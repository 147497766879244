!function () {
  function id(el) {
    return document.getElementById(el);
  }

  id('btn_contact').addEventListener("click", validateContact)

  function validateContact(event) {
    event.preventDefault();
    var contactName = id('contact_name');
    var contactEmail = id('contact_email');
    var contactPhone = id('contact_phone');
    var contactMessage = id('contact_message');

    if (contactName.value == '' || contactEmail.value == '' || contactPhone.value == '' || contactMessage.value == '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Preencha todos os campos do formulário para enviar a mensagem'
      });
      return false;
    }
    id('contact').submit();
    return true;
  }

  if (flashMessageSuccess) {
    Swal.fire({
      icon: 'info',
      text: flashMessageSuccess
    });
  }

  if (flashMessageError) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: flashMessageError
    });
  }

  tns({
    container: ".depoimentos",
    items: 1,
    prevButton: ".prev",
    nextButton: ".next",
    navContainer: ".dots",
    autoHeight: !0
  }), tns({
    container: "#logos",
    autoplay: !0,
    controls: !1,
    nav: !1,
    autoplayButtonOutput: !1,
    autoplayTimeout: 2e3,
    speed: 1e3,
    responsive: {
      1099: { items: 5 },
      879: { items: 4 },
      659: { items: 3 },
      439: { items: 2 },
      220: { items: 1 }
    }
  });

  function t(t) {
    var e = document.getElementById(t),
      n = parseFloat(e.getAttribute("data-number")) || 0,
      i = n - 0,
      o = 0,
      s = n > 0 ? 1 : -1,
      r = Math.abs(Math.floor(2e3 / i)),
      a = setInterval(function () {
        o += s, e.innerHTML = o, o == n && clearInterval(a)
      }, r)
  }
  setInterval(function () {
    var t;
    (t = document.getElementById("appslider")).lastElementChild.classList.add("active"), t.classList.add("transfomer"), setTimeout(function () {
      Array.from(t.children);
      var e = t.lastChild.previousElementSibling;
      t.insertBefore(e, t.firstChild), document.querySelector(".slide.active").classList.remove("active"), t.classList.remove("transfomer")
    }, 300)
  }, 3e3);
  var e, n = (e = !1, function () {
    e || (e = !0, setTimeout(function () {
      t("projetos_number")
    }, 0), setTimeout(function () {
      t("clientes_number")
    }, 300), setTimeout(function () {
      t("tecnologias_number")
    }, 600), setTimeout(function () {
      t("paises_number")
    }, 900))
  }),
    i = document.querySelectorAll(".v");

  function o(t) {
    var e = t.getBoundingClientRect();
    return e.top >= -t.clientHeight / 2 && e.left >= -t.clientWidth / 2 && e.bottom <= window.innerHeight + t.clientHeight / 2 && e.right <= window.innerWidth + t.clientWidth / 2
  }
  window.addEventListener("scroll", function (t) {
    i.forEach(function (t) {
      !0 === o(t) && (t.classList.add("visible"), t.hasAttribute("data-number") && n()), !1 === o(t) && t.classList.remove("visible")
    })
  })
}();
